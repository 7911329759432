import React from 'react';
import { useParams } from 'react-router-dom';
import './ServiceDetail.css';

function ServiceDetail() {
  const { id } = useParams();
  
  const serviceData = {
    1: {
      title: 'Virtual Accounting and Bookkeeping Services',
      content: (
        <div>
          <h2>Enhance Your Business with Seamless Bookkeeping Solutions</h2>
          <p>Accurate record-keeping and meticulous management of financial transactions are the cornerstones of effective financial operations. From tracking bills, expenses, deposits, and invoices to maintaining detailed financial records, we ensure your processes are smooth and efficient. At NexusGlobe, our extensive expertise in outsourced bookkeeping allows you to shift the burden of bookkeeping tasks from your team, enabling your in-house staff to focus on strategic accounting and business growth.</p>
          <p>We understand your need for a partner who can handle more than just basic tasks like accounts payable or tax returns. At NexusGlobe, we provide comprehensive outsourced bookkeeping services, designed with precision and care to meet the needs of your growing business. By outsourcing your bookkeeping to us, you can dedicate more time to your core business priorities while leaving the financial intricacies in our capable hands.</p>
          
          <h3>A Seamless Extension of Your Team</h3>
          <p>When you work with NexusGlobe’s offshore bookkeepers, it’s like having an experienced and reliable team member at your side—without the additional overhead. We prioritize creating a highly secure IT environment and leverage state-of-the-art accounting technologies to ensure top-quality work, delivered on time.</p>

          <h3>Staying Ahead of the Curve</h3>
          <p>The fast-paced evolution of accounting standards and practices can be daunting. At NexusGlobe, we ensure our team stays aligned with the latest industry trends and standards, so you don’t have to navigate these changes alone. Founders, CEOs, and executives of startups trust us to provide the expertise and support they need to maintain compliance and achieve their financial objectives.</p>

          <h3>Excellence in Bookkeeping</h3>
          <p>Our professional bookkeepers are highly skilled and trained to deliver prompt, accurate results. At NexusGlobe, we pride ourselves on equipping our team with the tools, experience, and collaborative spirit necessary to meet and exceed your expectations.</p>

          <h3>Outsource with Confidence</h3>
          <p>NexusGlobe’s outsourcing services for US businesses are led by a team of highly qualified professionals with extensive experience in the field. No matter where your business operates, our bookkeeping services can seamlessly integrate with your operations. We function as an extension of your in-house team, providing flexible, scalable solutions tailored to your unique needs.</p>
          <p>With our focus on security and the latest accounting technologies, we ensure that your financial data is protected and your expectations are met with precision. Let NexusGlobe take care of your bookkeeping, so you can focus on what truly matters—growing your business.</p>
        </div>
      ),
    },
    2: {
      title: 'Tax Filing Services',
      content: (
        <div>
          <p>Navigating the complexities of tax filing can be overwhelming, but it doesn’t have to be.
          At NexusGlobe, we provide tailored tax solutions to simplify the process and help you save time and money. Whether you’re an individual, a small business owner, or a self-employed professional, our tax experts are here to help.</p>
          
          <h3>Personal Tax Filing</h3>
          <p>Between tight deadlines, endless forms, and the ever-changing tax code, managing personal taxes can be a daunting task—even for the most organized individuals. This year, reduce your stress and maximize your returns with the help of our experienced tax professionals. We’ll handle the complexities so you can focus on what matters most.</p>

          <h3>Small Business Tax Filing</h3>
          <p>Owning a small business comes with enough challenges—taxes shouldn’t be one of them. Whether your business is structured as an LLC, C Corporation, or S Corporation, our tax experts provide customized strategies to minimize your tax liability while ensuring full compliance. Let us take tax filing off your to-do list, so you can focus on growing your business.</p>

          <h3>Tax Planning and Advice</h3>
          <p>Tax planning is often seen as tedious and stressful, but it doesn’t have to be. With our expert guidance, you can streamline your tax strategy and make smarter financial decisions. Outsource the headache to us and gain peace of mind knowing your taxes are in expert hands.</p>

          <h3>Self-Employed Taxes and Schedule C</h3>
          <p>Being self-employed means wearing many hats, and managing taxes is often one of the most complex. Our tax experts specialize in helping self-employed professionals file accurate Schedule C forms, navigate deductions, and avoid unnecessary stress. Trust us to handle your tax needs, so you can stay focused on running your business.</p>

          <h3>Tech Employees and Equity Compensation</h3>
          <p>Equity compensation can be a valuable benefit, but managing the associated taxes is often challenging. Our tax professionals provide expert advice on stock options, restricted stock units (RSUs), and other equity compensation strategies. We’ll help you navigate the tax implications and make informed decisions to maximize your financial outcomes.</p>

          <p>Simplify your tax season with NexusGlobe.Contact us today to schedule a consultation and discover how we can help you achieve stress-free, accurate, and optimized tax filings.</p>
        </div>
      ),
    },
    3: {
      title: 'Consulting Services',
      content: (
        <div>
          <p>At NexusGlobe, we provide comprehensive consulting solutions tailored to meet the diverse needs of businesses worldwide.
          Whether you’re establishing your presence in a new market, optimizing your financial strategy, or assessing the value of your enterprise, our expert team is here to guide you every step of the way.</p>

          <h3>Business Setup in India</h3>
          <p>Expanding your business to India can be both exciting and challenging. With its dynamic economy and diverse market opportunities, India is a promising destination for businesses. Our consulting services streamline the process, offering end-to-end support for:</p>
          <ul>
            <li>Company incorporation and compliance with Indian laws.</li>
            <li>Regulatory approvals, including FDI guidelines and RBI compliance.</li>
            <li>Tax registration, GST compliance, and corporate structuring.</li>
          </ul>
          <p>Let us simplify the complexities of setting up a business in India, so you can focus on growth and success.</p>

          <h3>Business Setup in the USA</h3>
          <p>The USA remains a top destination for businesses looking to tap into a global market. Whether you’re an international entrepreneur or a growing business expanding into the US, we provide tailored solutions for:</p>
          <ul>
            <li>Choosing the right business structure (LLC, Corporation, S-Corp, etc.).</li>
            <li>Legal and tax compliance, including EIN registration and state-specific filings.</li>
            <li>Bank account setup, operational planning, and employment guidelines.</li>
            <li>Strategic market entry advisory and regulatory compliance.</li>
          </ul>
          <p>Our experts ensure a smooth setup process, allowing you to establish a strong foundation in one of the world’s most competitive markets.</p>

          <h3>Financial Modeling</h3>
          <p>Strong financial modeling is the backbone of sound business decisions. Our financial modeling services help you visualize your company’s financial performance and plan for the future with confidence. We provide:</p>
          <ul>
            <li>Customized financial models for budgeting, forecasting, and investment analysis.</li>
            <li>Scenario planning and sensitivity analysis to evaluate potential risks and opportunities.</li>
            <li>Assistance in preparing investor presentations and funding strategies.</li>
          </ul>
          <p>Whether you're planning for growth, managing cash flow, or securing funding, our detailed financial models empower you to make informed decisions.</p>

          <h3>Business Valuation</h3>
          <p>Understanding the true value of your business is essential for strategic planning, mergers, acquisitions, or raising capital. Our business valuation services provide an accurate and comprehensive assessment based on:</p>
          <ul>
            <li>Industry-standard valuation methods, such as DCF, EBITDA multiples, and market comparables.</li>
            <li>Analysis of financial performance, market position, and growth potential.</li>
            <li>Strategic recommendations tailored to your goals, whether you’re selling, merging, or attracting investors.</li>
          </ul>
          <p>With our expertise, you gain clarity and confidence in understanding your business’s worth, enabling you to take the next steps with precision.</p>
            <br />
          <p><strong>Partner with Us for Success </strong>at NexusGlobe, we combine global expertise with local insights to deliver consulting solutions that drive results. Whether you’re expanding, optimizing, or planning for the future, we’re here to help you navigate every challenge and seize every opportunity.</p>
          <p><strong>Contact us today</strong> to explore how our consulting services can elevate your business to the next level.</p>
        </div>
      ),
    },
    4: {
      title: 'Virtual CFO Services',
      content: (
        <div>
          <p>In today’s fast-paced and competitive business environment, companies need more than just bookkeeping and accounting to thrive.
          They need strategic financial leadership that helps them plan, grow, and succeed. That’s where Virtual CFO Services come in.</p>

          <p>A Virtual CFO offers all the benefits of an in-house Chief Financial Officer—expert financial guidance, forecasting, and strategic planning—without 
            the overhead costs of a full-time executive. Whether you’re a startup aiming to scale or an established business seeking to streamline finances, our Virtual CFO solutions are designed to align with your unique goals.</p>

          <h3>What is a Virtual CFO?</h3>
          <p>A Virtual CFO is a highly skilled financial expert who works remotely to provide strategic financial insights, analysis, and guidance. By leveraging cutting-edge technology and a results-driven approach, Virtual CFOs deliver the expertise you need, when you need it, without being tied to a physical location.</p>

          <h3>Virtual CFO Services</h3>
          <p>In the dynamic and competitive business environment , financial leadership is a critical component for sustained success. Companies need more than just traditional bookkeeping and accounting; they require forward-thinking financial strategies that drive growth, enhance profitability, and mitigate risks. Virtual CFO services provide precisely that—executive-level financial expertise tailored to the unique needs of your business, without the cost of hiring a full-time Chief Financial Officer.
            A Virtual CFO (Chief Financial Officer) is a seasoned financial expert who works remotely, offering businesses the strategic insight and guidance they need to thrive. From financial planning to compliance, fundraising to cost optimization, Virtual CFOs are transforming how businesses manage their finances, helping them operate smarter, not harder.
          </p>
          

          <h3>What We Offer</h3>
          <p>Our Virtual CFO services are comprehensive, covering every aspect of financial leadership. Whether you’re a startup aiming to scale or an established organization looking to streamline operations, we provide solutions customized to your business goals.
            Through meticulous financial planning and strategy development, we help you chart a roadmap for success. By analyzing your cash flow, forecasting revenue trends, and preparing detailed budgets, we ensure you’re prepared for growth while mitigating potential risks. Our real-time financial reporting and analysis help you make informed decisions, while our expertise in cost optimization identifies areas to cut unnecessary expenses and maximize profits.
            As compliance and regulatory requirements in the US become increasingly complex, our Virtual CFO services provide peace of mind. We stay ahead of the latest federal and state financial regulations to ensure your business remains compliant. If you’re seeking funding or managing investor relations, we assist with capital raising, prepare compelling pitch decks, and act as a trusted liaison between your business and investors.
            Technology plays a pivotal role in modern financial management, and we leverage advanced tools to streamline operations. From implementing cutting-edge accounting software to automating processes, we help you harness the power of technology to make data-driven decisions efficiently and effectively.
           </p>
          

          <h3>Transform Your Business with Expert Financial Leadership</h3>
          <p>Partnering with a Virtual CFO is about more than numbers—it’s about unlocking your business’s full potential. From day-to-day financial operations to long-term strategic planning, we provide the expertise, tools, and insights you need to achieve sustainable success. With our guidance, you’ll be equipped to make smarter decisions, optimize resources, and drive growth.</p>
            <p>Ready to take your financial management to the next level? <strong>Contact us today</strong> to learn how our Virtual CFO services can revolutionize your business. Together, let’s turn your vision into reality
            </p>

        </div>
      ),
    },
  };

  const service = serviceData[id];

  return (
    <div className="service-detail">
      <h1 className="service-title">{service.title}</h1>
      <div className="service-content">{service.content}</div>
    </div>
  );
}

export default ServiceDetail;
