import React from 'react';
import { Link } from 'react-router-dom';
import './Services.css';

function Services() {
  const services = [
    { id: 1, title: 'Virtual Accounting and Bookkeeping Services' },
    { id: 2, title: 'Tax Filing Services' },
    { id: 3, title: 'Consulting Services' },
    { id: 4, title: 'Virtual CFO Services' },
  ];

  return (
    <div className="services-page">
      <h1 className="services-title">Our Services</h1>
      <hr className="title-underline" />
      <div className="services-bars-container">
        {services.map((service, index) => (
          <Link
            key={service.id}
            to={`/service/${service.id}`}
            className={`service-bar ${index % 2 === 0 ? 'from-left' : 'from-right'}`}
          >
            {service.title}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Services;
